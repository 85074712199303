import { Source } from './Source.interface';

export type SourceTypes = {
  [key in SourceType]: SourceTypeSchema;
};

export type DestinationTypes = {
  [key in DestinationType]: DestinationTypeSchema;
};

export type SourceType =
  | 'HTTP'
  | 'WEBHOOK'
  | 'BRIDGE'
  | 'EBAY'
  | 'FRONTAPP'
  | 'TWITTER'
  | 'TWILIO'
  | 'STRIPE'
  | 'RECHARGE'
  | 'GITHUB'
  | 'SHOPIFY'
  | 'POSTMARK'
  | 'TYPEFORM'
  | 'XERO'
  | 'SVIX'
  | 'REPLICATE'
  | 'ADYEN'
  | 'AKENEO'
  | 'ZOOM'
  | 'OURA'
  | 'GITLAB'
  | 'PROPERTY-FINDER'
  | 'WOOCOMMERCE'
  | 'COMMERCELAYER'
  | 'HUBSPOT'
  | 'MAILGUN'
  | 'PERSONA'
  | 'PIPEDRIVE'
  | 'SENDGRID'
  | 'WORKOS'
  | 'SYNCTERA'
  | 'AWS_SNS'
  | 'THREE_D_EYE'
  | 'ENODE'
  | 'FAVRO'
  | 'LINEAR'
  | 'TWITCH'
  | 'WIX'
  | 'NMI'
  | 'ORB'
  | 'PYLON'
  | 'TRELLO'
  | 'REPAY'
  | 'SANITY'
  | 'SHOPLINE'
  | 'SQUARE'
  | 'SOLIDGATE'
  | 'CLOUDSIGNAL'
  | 'COURIER'
  | 'TELNYX'
  | 'DISCORD'
  | 'TOKENIO'
  | 'FISERV'
  | 'BONDSMITH'
  | 'VERCEL_LOG_DRAINS'
  | 'VERCEL'
  | 'TEBEX'
  | 'SLACK'
  | 'RAZORPAY'
  | 'MAILCHIMP'
  | 'PADDLE'
  | 'PAYPAL'
  | 'TREEZOR'
  | 'PRAXIS'
  | 'CUSTOMERIO'
  | 'FACEBOOK'
  | 'WHATSAPP'
  | 'TIKTOK'
  | 'AIRWALLEX'
  | 'ZENDESK'
  | 'UPOLLO'
  | 'LINKEDIN';

export type SourceAuthProvider = SourceType | SourceGenericAuthTypes;

export type TypeFeatureField = {
  label: string;
  name: string;
  type: 'text' | 'select' | 'password' | 'number' | 'textarea' | 'checkbox' | 'list' | 'object';
  custom_validations?: (schema: any) => any;
  placeholder?: string;
  required?: boolean;
  features?: IntegrationFeature[];
  options?: { value: string; label: string }[];
};
export type TypeFeatureFields = TypeFeatureField[];

type SourceGenericAuthTypes = 'HMAC' | 'BASIC_AUTH' | 'API_KEY';
type SourceTypeFeatureTypes = IntegrationFeature | 'CUSTOM_HTTP_METHOD' | 'CUSTOM_RESPONSE';
type SourceTypeFeatureTypesManaged = {
  managed: true;
  default_value: string;
};
export type SourceTypeFeatureTypesUnmanaged = {
  managed?: false;
  required?: boolean;
  advanced?: boolean;
  default_value?: string;
  fields?: TypeFeatureFields;
  auth?: {
    [key in SourceGenericAuthTypes]: {
      label: string;
      features: IntegrationFeature[];
      fields: TypeFeatureFields;
    };
  };
};
export type SourceTypeFeature = SourceTypeFeatureTypesManaged | SourceTypeFeatureTypesUnmanaged;
export function isSourceTypeFeatureUnmanaged(
  feature: { managed?: boolean } | undefined
): feature is SourceTypeFeatureTypesUnmanaged {
  return feature?.managed !== true;
}
type FeatureOptions = SourceTypeFeatureTypesManaged | SourceTypeFeatureTypesUnmanaged;
type HandshakeFeature = boolean | FeatureOptions;
export type SourceTypeFeatures = Partial<{
  [K in SourceTypeFeatureTypes]: K extends 'HANDSHAKE' ? HandshakeFeature : FeatureOptions;
}>;

export interface SourceTypeSchema {
  label: string;
  category: 'platform' | 'http';
  popular?: boolean;
  icon?: string;
  features: SourceTypeFeatures;
  configuration_alert?: string;
  external_docs_url?: string;
  icon_domain?: string;
}

export type DestinationType = 'HTTP' | 'CLI' | 'MOCK_API';
// | 'AWS_S3'
// | 'AWS_DYNAMO_DB'
// | 'AWS_EVENTBRIDGE'
// | 'AWS_KINESIS';

type DestinationTypeFeatureTypes =
  | IntegrationFeature
  | 'CUSTOM_URL'
  | 'VERIFICATION'
  | 'CUSTOM_DESTINATION_HTTP_METHOD'
  | 'CUSTOM_PATH_FORWARDING'
  | 'MAX_THROUGHPUT_RATE';
type GENERIC_AUTH_TYPES =
  | 'HOOKDECK_SIGNATURE'
  | 'CUSTOM_SIGNATURE'
  | 'BASIC_AUTH'
  | 'API_KEY'
  | 'BEARER_TOKEN'
  | 'OAUTH2_CLIENT_CREDENTIALS'
  | 'OAUTH2_AUTHORIZATION_CODE'
  | 'AWS_SIGNATURE';
export type DestinationTypeFeatureTypesManaged = {
  managed: true;
  default_value: string;
};
export type DestinationTypeFeatureTypesUnmanaged = {
  managed?: false;
  required?: boolean;
  advanced?: boolean;
  default_value?: string;
  fields?: TypeFeatureFields;
  auth?: {
    [key in GENERIC_AUTH_TYPES]: {
      label: string;
      fields: TypeFeatureFields;
    };
  };
};
export type DestinationTypeFeature =
  | DestinationTypeFeatureTypesManaged
  | DestinationTypeFeatureTypesUnmanaged;
export function isDestinationTypeFeatureUnmanaged(
  feature: { managed?: boolean } | undefined
): feature is DestinationTypeFeatureTypesUnmanaged {
  return feature?.managed !== true;
}
export type DestinationTypeFeatures = Partial<{
  [key in DestinationTypeFeatureTypes]: DestinationTypeFeature;
}>;
export interface DestinationTypeSchema {
  label: string;
  category: 'aws' | 'generic';
  description?: string;
  icon?: string;
  features: DestinationTypeFeatures;
  configuration_alert?: string;
}

export const basic_auth_integration_providers = ['BASIC_AUTH', 'POSTMARK', 'PIPEDRIVE'];

export interface HMACIntegrationConfigs {
  webhook_secret_key: string;
  algorithm: HMACAlgorithms;
  header_key: string;
  encoding: 'base64' | 'base64url' | 'hex';
  delimiter: '.' | ':';
  prefix_data_with_timestamp: boolean;
}

export interface HandledHMACConfigs extends Pick<HMACIntegrationConfigs, 'webhook_secret_key'> {}

export interface BasicAuthIntegrationConfigs {
  username: string;
  password: string;
}

export interface APIKeyIntegrationConfigs {
  header_key: string;
  api_key: string;
}

export interface Crc32IntegrationConfigs {
  webhook_id: string;
}

export interface EbayIntegrationConfigs {
  client_id: string;
  client_secret: string;
  dev_id: string;
  verification_token: string;
  environment: string;
}

export interface FiservIntegrationConfigs {
  webhook_secret_key: string;
  store_name: string;
}

export interface HandledAPIKeyIntegrationConfigs extends Pick<APIKeyIntegrationConfigs, 'api_key'> {
  api_key: string;
}

export interface PublicKeyIntegrationConfigs {
  public_key: string;
}

export interface ShopifyIntegrationConfigs
  extends Pick<HMACIntegrationConfigs, 'webhook_secret_key'> {
  rate_limit_period?: 'minute' | 'second';
  rate_limit?: number;
  api_key?: string;
  api_secret?: string;
  shop?: string;
}

export interface VercelLogDrainsIntegrationConfigs {
  webhook_secret_key?: string;
  vercel_log_drains_secret?: string;
}

export interface SvixIntegrationConfigs extends HandledHMACConfigs {
  headers_prefix: string;
}

export interface TiktokIntegrationConfigs {
  webhook_secret_key: string;
  app_key: string;
}

export type HMACAlgorithms = 'md5' | 'sha1' | 'sha256' | 'sha512';

export type IntegrationConfig =
  | APIKeyIntegrationConfigs
  | EbayIntegrationConfigs
  | HandledAPIKeyIntegrationConfigs
  | HandledHMACConfigs
  | SvixIntegrationConfigs
  | HMACIntegrationConfigs
  | APIKeyIntegrationConfigs
  | BasicAuthIntegrationConfigs
  | ShopifyIntegrationConfigs
  | PublicKeyIntegrationConfigs
  | VercelLogDrainsIntegrationConfigs
  | Crc32IntegrationConfigs;

export type IntegrationFeature = 'VERIFICATION' | 'HANDSHAKE';

export type AuthProvider = SourceType | 'HMAC' | 'BASIC_AUTH' | 'API_KEY';
export interface Integration {
  id: string;
  team_id: string;
  label: string;
  provider: AuthProvider;
  features: IntegrationFeature[];
  configs: IntegrationConfig;
  sources: Source[];
  updated_at: string;
  created_at: string;
}

export interface IntegrationCreateInput {
  label: string;
  team_id: string;
  configs: object;
  provider: AuthProvider;
  features: IntegrationFeature[];
  encrypted_configs?: string;
  iv?: string;
}

export interface IntegrationUpdateInput {
  label?: string;
  team_id?: string;
  configs: object;
  features?: IntegrationFeature[];
  encrypted_configs?: string;
  iv?: string;
}
